// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  const [shopikUser, setShopikUser] = useState({});
  const [customer, setCustomer] = useState({});
  const [uploaderStyle, setUploaderStyle] = useState(0);

  useEffect(() => {
    if (user) {
      handleUserLogin(user);
    }
  }, [user]);

  
  const handleUserLogin = async (user) => {
    const userId = user.uid;
    const userRef = doc(collection(db, "users"), userId);
    const docSnap = await getDoc(userRef);
    
    console.log(userId)
    // if (docSnap.exists()) {
    //   const userData = docSnap.data();
    //   setShopikUser(userData);
    //   // Fetch uploader style
    //   getUploaderStyle(userData.customerId);
    // } else {
    //   // Handle new user logic if necessary
    //   const newUserData = {
    //     userId: user.uid,
    //     name: user.displayName,
    //     email: user.email,
    //     // Add other fields as needed
    //   };
    //   await setDoc(userRef, newUserData);
    //   setShopikUser(newUserData);
    // }
  };

//   const handleUserLogin = async (user, signInMethod) => {
//     const customerId = params['customer'];
//     const dbUserRef = collection(db, "users");
//     const userId = user.uid;
//     const userRef = doc(dbUserRef, userId);
//     const docSnap = await getDoc(userRef);
    
//     let userData;
//     if (docSnap.exists()) {
//       userData = docSnap.data();
//       if (userData.customerId !== customerId) {
//         setIsDialogVisible(true);
//       } else {
//         getUploaderStyle(shopikUser)
//         setShopikUser(userData);  
//       }
//       setUserDict(userData);
//     } else {
//       const newUserData = {
//         appVersion: '2.7',
//         created: formatCurrentTime(),
//         customerId: customerId,
//         userId: userId,
//         name: user.displayName,
//         lastOnline: formatCurrentTime(),
//         signInMethod: signInMethod.replace('.com', ''),
//       };
//       addUserToCustomer(newUserData);
//       setUserDict(newUserData);
//     }
//   };



//   const getUploaderStyle = async (customerId) => {
//     const customerRef = doc(collection(db, "customers"), customerId);
//     const customerData = (await getDoc(customerRef)).data();
//     if (customerData && customerData.uploaderStyle) {
//       setUploaderStyle(customerData.uploaderStyle);
//     }
//   };

  const value = {
    user,
    loading,
    error,
    shopikUser,
    uploaderStyle,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
import React, { useEffect, useState } from 'react';
import { db, appShopifyHandoff, storage } from '../firebase';
import { doc, getDoc, setDoc, collection, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
const inflect = require('inflection');

const ProductEdit = ({ customerId, productId, updateState, uploaderStyle, groupIndex  }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  const [formatPrefs, setFormatPrefs] = useState({});
  const [customFields, setCustomFields] = useState({});
  const [compareAtPrice, setCompareAtPrice] = useState(null);
  const [additionalTags, setAdditionalTags] = useState([]);
  const [newImage, setNewImage] = useState(null); // For storing new image
  const [newImageUrl, setNewImageUrl] = useState(null); // For storing new image

  const handleChange = (e) => {
    const { name, value } = e.target;
    formData[name] = value
    let title = updateTitle(formData)
    let description = updateDescription(formData)
    setFormData({
      ...formData,
      ['Title']: title,
      ['Description']: description,
      [name]: value
    });
  };

  const areDropdownsFilled = () => {
    return Object.keys(dropdownFields).every((key) => {
      const value = formData[key];
  
      return value !== 'Select an option' && value !== 'Not Available' && value != '';
    });
  };
 
  // Handle dropdown changes
  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      let updatedFormData = { ...prevFormData, [name]: value }; // Update formData with new value

      if (name === 'Category') {
        // Remove any existing fields that shouldn't be in formData based on category change
        Object.keys(categoryToFieldsMap).forEach((category) => {
          categoryToFieldsMap[category].forEach((field) => {
            if (updatedFormData.hasOwnProperty(field) && category !== value) {
              delete updatedFormData[field]; // Remove fields not related to the selected category
            }
          });
        });

        // Add relevant fields to formData based on the selected category
        const fieldsForCategory = categoryToFieldsMap[value] || [];
        fieldsForCategory.forEach((field) => {
          if (!updatedFormData.hasOwnProperty(field)) {
            updatedFormData[field] = ''; // Add the new field to formData with an empty value
          }
        });
      }

      return updatedFormData;
    });
  };

  const constants = {
    EXCLUDE_FROM_PRODUCT_TERMS: ['unknown', 'not applicable', 'not provided', 'not available', 'not specified'] 
  };
  // Add empty/placeholder value in dropdown fields for validation
  const dropdownFields = {
    'Sleeve Length': ['Select an option', 'Sleeveless', 'Strapless', 'Off Shoulder', 'Short Sleeve', 'Long Sleeve', 'One Shoulder'],
    'Color': [
      'Select an option', 'Red', 'Orange', 'Yellow', 'Green', 'Teal', 'Blue', 'Purple', 'Lavender', 
      'Pink', 'Brown', 'Black', 'White', 'Gray', 'Tan', 'Gold', 'Silver', 
      'Multi', 'Off White', 'Navy', 'Light Blue'
    ],
    'Size': ['Select an option', 'S', 'M', 'LG', 'XL', 'XXL', 'Universal'], 
    'Condition': ['Select an option', 'Like-New', 'New With Tags', 'Gently Used'],
    'Waist Length': ['Select an option', 'High Waisted', 'Low Rise', 'Mid Rise'],
    'Type': ['Select an option', 'Maxi', 'Mini', 'Midi'],
    'Product Type': [
      'Select an option', 'Leggings', 'Sports Bras', 'Blazers', 'Jackets', 'Coats', 'Dresses', 'Hats', 
      'Bikinis', 'Bikini Tops', 'Bikini Bottoms', 'One-Pieces', 'Cover Ups', 'Skirts', 
      'Tank Tops', 'Crop Tops', 'Long Sleeve Tops', 'Blouses', 'T-Shirts', 'Graphic Tees', 
      'Cardigans', 'Sweaters', 'Button Ups', 'Turtlenecks', 'Sweater Vests', 'Jerseys', 
      'Pants', 'Jeans', 'Shorts', 'Matching Sets', 'Jumpsuits', 'Rompers', 'Hoodies', 
      'Sweatshirts', 'Sweatpants', 'Pajamas', 'Robes', 'Handbags', 'Clutches', 'Purses', 
      'Fanny Packs', 'Backpacks', 'Totes', 'Wallets', 'Phone Cases', 'Eye Makeup', 
      'Face Makeup', 'Lip Makeup', 'Perfume', 'Lashes', 'Fake Nails', 'Rings', 'Necklaces', 
      'Bracelets', 'Belts', 'Scarves', 'Watches', 'Sunglasses', 'Heels', 'Sneakers', 
      'Sandals', 'Clogs & Mules', 'Boots'
    ],
    'Category': [
      'Select an option', 'Activewear', 'Coats & Jackets', 'Dresses', 'Hats', 'Swimwear', 'Skirts', 
      'Shirts', 'Pants', 'Shorts', 'Outfit Sets', 'One-Pieces', 
      'Loungewear', 'Handbags, Wallets & Cases', 'Mobile & Smart Phone Accessories', 'Makeup', 
      'Jewelry', 'Clothing Accessories', 'Shoes'
    ]
  };
  
  // Mapping categories to fields
  const categoryToFieldsMap = {
    Shirts: ['Sleeve Length'],
    Pants:  ['Waist Length'],
    Dresses: ['Type'],
    Skirts: ['Type'],
  };

  function buildSpecs(map) {
    const addAfter = [];
    const newArray = [];
    for (const item in map) {
      var value = map[item]
      if (!constants.EXCLUDE_FROM_PRODUCT_TERMS.includes(value.toLowerCase())) {
        newArray.push([item, value])
      } else {
        addAfter.push(item);
      }
    }
  
    for (const item of addAfter) {
      var value = map[item]
      newArray.push([item, value])
    }
    const specDict = {};
    for (const item of newArray) {
      specDict[item[0]] = item[1]
    }
    return specDict;
  }

  function updateTitle(consolidatedData) {
      if (formatPrefs.hasOwnProperty('titleFormat')) {
          let baseTitle = '';
          formatPrefs.titleFormat.split(',').forEach(field => {
              if (consolidatedData.hasOwnProperty(field)) {
                if (consolidatedData[field] !== 'Unknown') {
                  baseTitle += consolidatedData[field].replace(/,/g, ' and ') + ' ';
                }
              } else if (field.includes('literal')) {
                  baseTitle += field.replace('literal','') + ' ';
              }
          });
          baseTitle = baseTitle.trim();
          baseTitle = singularizeLastWord(baseTitle);
          if (baseTitle === '') {
              baseTitle = consolidatedData['Title'];
          }
          return baseTitle;
      } 
      return consolidatedData['Title']
  }

  function updateDescription(consolidatedData) {
    if (formatPrefs.hasOwnProperty('descriptionFormat')) {
          let baseDescription = '';
          formatPrefs.descriptionFormat.split(',').forEach(field => {
              if (consolidatedData.hasOwnProperty(field)) {
                  baseDescription += `${field}: ${consolidatedData[field]}<br>`;
              } else if (customFields.hasOwnProperty(field)) {
                  baseDescription += `${field}: ${customFields[field]}<br>`;
              }
          });
          return baseDescription;
      } 
      return consolidatedData['Description'] 
    }

  function singularizeLastWord(title) {
      const words = title.split(' ');
      const word = words[words.length - 1];
      const singularWord = inflect.singularize(word);
      words[words.length - 1] = singularWord;
      return words.join(' ');
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!areDropdownsFilled()) return;

    const dbUserRef = collection(db, "customers");
    const userRef = doc(dbUserRef, customerId);
    const productsRef = collection(userRef, "products");
    const pRef = doc(productsRef, productId);
    const productData = (await getDoc(pRef)).data();
    var existingData = productData.consolidatedData;

    for (const key in formData) {
      existingData[key] = formData[key];
    }
    console.log(productId);
    await setDoc(pRef, { "consolidatedData": existingData }, { merge: true });

    await manualShopifyPost(productData, existingData);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file); // Create a temporary URL for preview
      setNewImageUrl(imageURL);
      setNewImage(file);
    }
  };

  const handleImageClick = () => {
    document.getElementById(`new-image-${groupIndex}`).click(); // Use groupIndex to ensure uniqueness
  };

  const toggleImageAtIndex = (index) => {
    setImageUrls((prevUrls) =>
      prevUrls.map((url, i) =>
        i === index
          ? url.includes('noBackground')
            ? url.replace('noBackground', 'original') // Toggle to original
            : url.replace('original', 'noBackground') // Toggle to noBackground
          : url
      )
    );
  };

  const manualShopifyPost = async (productData, consolidatedData) => {
    try {
      if (!customerId || !productId) {
        return;
      }
      setIsSubmitting(true);
      let productDataCopy = { ...consolidatedData };

      productDataCopy.shopifyTitle = consolidatedData.Title;
      productDataCopy.shopifyDescription = consolidatedData.Description;
      if (additionalTags) {
        productDataCopy.shopifyAdditionalTags = additionalTags;
      }
      if (compareAtPrice) {
        productDataCopy.shopifyCompareAtPrice = compareAtPrice;
      }
      if (newImage) {
        const uploadImageToFirebase = async (file) => {
          const storageRef = ref(storage, `temp/${Date.now()}_${file.name}`);
          await uploadBytes(storageRef, file);
          const url = await getDownloadURL(storageRef);
          return url;
        };
        const url = await uploadImageToFirebase(newImage);
        imageUrls.push(url);
      }
      const payloadDict = {
        customerId: customerId,
        image_urls: imageUrls,
        productDocument: productId,
        product_results: productDataCopy
      };

      appShopifyHandoff(payloadDict).then((innerData) => {
        console.log('Shopify post sent off to app ' + innerData);
        if (innerData.data.status) {
          updateState(groupIndex);
        } else {
          console.log('Shopify error');
        }
      });
    } catch (error) {
      console.error('Error:', error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const custColRef = collection(db, "customers");
    const custDocRef = doc(custColRef, customerId);
    const prodColRef = collection(custDocRef, "products");
    const prodDocRef = doc(prodColRef, productId);
    
    setupFormatting(customerId, productId);
    
    const unsub = onSnapshot(prodDocRef, async (productDoc) => {
      const data =  productDoc.data();
      if (data['consolidatedData']) {
        const specDict = buildSpecs(data['consolidatedData']);
        setFormData(specDict);
        setIsLoading(false);
        if (data.gptPriceData) {
          if (data.gptPriceData.new) {
            setCompareAtPrice(data.priceOptions.new)
          }
        }
        if (data.shopifyAdditionalTags) {
          setAdditionalTags(data.shopifyAdditionalTags)
        }
        unsub(); // Unsubscribe after loading specs
      } 
      if (data['mainImages']) {
        setImageUrls(data.mainImages.filter(image => !image.isHidden).map(image => image.urlString));
      }
    });
  
    // Cleanup function to unsubscribe when component unmounts or customerId or productId change
    return () => {
      unsub();
    };
  }, [customerId, productId]); // Add customerId and productId to dependency array

  const setupFormatting = async (customerId, productId) => {
    const custColRef = collection(db, "customers");
    const custDocRef = doc(custColRef, customerId);
    const prodColRef = collection(custDocRef, "products");
    const prodDocRef = doc(prodColRef, productId);
    const custDict = (await getDoc(custDocRef)).data();
    const productDict = (await getDoc(prodDocRef)).data();
    
    let formatData = {};
    let customFields = {};

    if (custDict.hasOwnProperty('outputPrefs')) {
        formatData = custDict.outputPrefs;
        setFormatPrefs(formatData);
        if (formatData.hasOwnProperty('customUserInfo') && productDict.hasOwnProperty('uploadedBy')) {
            let userId = productDict.uploadedBy.replace('localDebugDelete', '');
            const userColRef = collection(db, "users");
            const userDocRef = doc(userColRef, userId);
            const userDict = (await getDoc(userDocRef)).data();

            for (let field in formatData.customUserInfo) {
                let valueString = formatData.customUserInfo[field];
                let baseValue = '';

                valueString.split(',').forEach(innerField => {
                    if (userDict.hasOwnProperty(innerField)) {
                        baseValue += userDict[innerField] + ' ';
                    }
                });

                baseValue = baseValue.trim();
                customFields[field] = baseValue;
            }
        }
        setCustomFields(customFields);
    }
  }

  return (
    <>
    {imageUrls.length > 0 && (
      <div id="image-info" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <div className="demo-image image-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {imageUrls.map((url, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
              <img id={`processed-image-${index}`} src={url} alt={`Product Image ${index}`} width={300} style={{ background: 'white', borderRadius: '2px', border: '2px solid gray' }} />
              { uploaderStyle ===1 && (<>Please keep the background removed for product images and re-add it for any photos of you in the product</>)}
              <button className='custom-button-ingroup' onClick={() => toggleImageAtIndex(index)} style={{ marginTop: '10px' }}>
              Background ON/OFF
              </button>
            </div>
          ))}
        </div>
        <div id="item-info" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          {formData && (
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              {Object.entries(formData).map(([key, value]) => {
                const isCustomKey = key === 'Title';
                const isDescription = key === 'Description';
                const isDropdown = dropdownFields.hasOwnProperty(key); // Check if the key should use a dropdown
  
                return (
                  <React.Fragment key={key}>
                    {uploaderStyle === 1 && (
                      <>
                        {!isDescription && (
                          <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <label htmlFor={key} style={{ display: 'block' }}>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                            {isDropdown ? (
                              <select
                                className="custom-select"
                                id={key}
                                name={key}
                                value={value}
                                onChange={handleDropdownChange}
                                style={{ padding: '5px', width: '80%', color: 'black', textAlign: 'center' }}
                              >
                                {dropdownFields[key].map((option, index) => (
                                  <option key={index} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <input
                                className={isCustomKey ? 'custom-text-input read-only-format' : 'custom-text-input'}
                                type="text"
                                id={key}
                                name={key}
                                value={value}
                                onChange={handleChange}
                                style={{
                                  padding: '5px',
                                  width: '80%',                      
                                  color: 'black',
                                  fontWeight: isCustomKey ? 'bold' : 'normal',
                                  textAlign: 'center',
                                }}
                                readOnly={isCustomKey} // Make the input read-only for custom keys
                              />
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {uploaderStyle === 0 && (
                      <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <label htmlFor={key} style={{ display: 'block' }}>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                        <input
                          className='custom-text-input'
                          type="text"
                          id={key}
                          name={key}
                          value={value}
                          onChange={handleChange}
                          style={{ padding: '5px', width: '80%', color: 'black', textAlign: 'center' }}
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
              {Object.keys(formData).length !== 0 && (
                <>
                  {isSubmitting ? (
                    <div className='spinner'></div>
                  ) : (
                    <>
                      {uploaderStyle === 1 && (
                        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                          <b>Add a pic of you! This helps the product sell faster</b><br /><br />
                          <img
                            src={newImageUrl || 'images/no-image.png'} // Placeholder image or uploaded image
                            alt="Click to upload"
                            width={300}
                            style={{ cursor: 'pointer', border: '2px solid gray' }}
                            onClick={handleImageClick}
                          />
                          {/* Hidden File Input */}
                          <input
                            type="file"
                            id={`new-image-${groupIndex}`}
                            style={{ display: 'none' }} // Hide the file input
                            onChange={handleImageChange}
                          />
                        </div>
                      )}
                      <button 
                        className='custom-button-ingroup' 
                        type="submit" 
                        style={{ padding: '10px 20px', marginTop: '10px' }} 
                        disabled={!areDropdownsFilled() || isSubmitting} // Disable if dropdowns aren't filled or submitting
                      >
                        Submit
                      </button>                     
                    </>
                  )}
                </>
              )}
            </form>
          )}
        </div>
      </div>
    )}
    {isLoading && (
      <div className="demo-image image-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <div className='spinner'></div>
        { uploaderStyle === 1 ? (<>This might take a few minutes — sit back and get comfy. We’ll be ready in no time!</>) : (<> 
        ({imageUrls.length === 0 && <>Analyzing and enhancing images....</>}
        {imageUrls.length > 0 && <>Determining item specifications and price....</>}
        {!formData && <>Getting more detailed specifications and verifying....</>}
        </>)
        }
      </div>
    )}
  </>); 
}

export default ProductEdit;
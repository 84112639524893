// Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authContext';
import { auth, db  } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const { user } = useAuth();
  const navigate = useNavigate();


    // If the user is already logged in, redirect to the dashboard
    useEffect(() => {
    if (user) {
        navigate('/dashboard'); // Redirect to dashboard if logged in
    }
    }, [user, navigate]);


  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((result) => {
      window.parent.postMessage({ action: 'closePopup' }, '*');
    }).catch((error) => {
      console.error(error);
    });
  };

  const signInWithApple = () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    signInWithPopup(auth, provider).then((result) => {
      window.parent.postMessage({ action: 'closePopup' }, '*');
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className='login-text'> 
       <img src="images/logo.png" loading="lazy" alt="" style={{ maxWidth: '300px' }} />
       <br/>
        <h3>Sign in to your account</h3>
        <br/>
        
      <button className="signin-button" onClick={signInWithGoogle}>
      <img src="images/google-signin.png" alt="Google Sign in"/>
      </button>
      <button className="signin-button"  onClick={signInWithApple}>
      <img src="images/apple-signin.png" alt="Apple Sign in"/>
        </button>
        <br/>
        <div style={{ maxWidth: '300px'}} >
        Don't have an account? <br/>
        Create one by signing in above!
            </div>
    </div>
  );
};

export default Login;
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { post } from './api'; // Your custom API wrapper
import axios from 'axios'; // For any other axios operations, if needed

const stripePromise = loadStripe('your_stripe_public_key_here'); // Replace with your Stripe public key

const CheckoutForm = ({ paymentType, customerId, apiKey }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState('');

    // Handles Subscription Creation
    const handleSubscription = async () => {
        const priceId = 'your_price_id_here'; // Replace with your actual price ID for subscription
        try {
            const data = await post('/create-subscription', apiKey, { customerId, priceId });
            setClientSecret(data.clientSecret);
        } catch (error) {
            console.error('Subscription creation failed:', error);
        }
    };

    // Handles One-Time Payment (for credits)
    const handleOneTimePayment = async () => {
        const amount = 5000; // Replace with the amount in cents (e.g., 50.00 USD = 5000)
        try {
            const data = await post('/create-payment-intent', apiKey, { customerId, amount });
            setClientSecret(data.clientSecret);
        } catch (error) {
            console.error('Payment intent creation failed:', error);
        }
    };

    // Handle payment submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        try {
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

            if (result.error) {
                console.error('Payment error:', result.error.message);
            } else if (result.paymentIntent.status === 'succeeded') {
                console.log('Payment succeeded');
            }
        } catch (error) {
            console.error('Payment submission failed:', error);
        }
    };

    return (
        <div>
            {paymentType === 'subscription' ? (
                <button onClick={handleSubscription}>Subscribe</button>
            ) : (
                <button onClick={handleOneTimePayment}>Buy Credits</button>
            )}

            {clientSecret && (
                <form onSubmit={handleSubmit}>
                    <CardElement />
                    <button type="submit" disabled={!stripe}>
                        Pay
                    </button>
                </form>
            )}
        </div>
    );
};

const PaymentPage = ({ customerId, apiKey }) => {
    const [paymentType, setPaymentType] = useState('subscription'); // Default to subscription

    return (
        <Elements stripe={stripePromise}>
            <h1>Choose a Payment Method</h1>
            <div>
                <button onClick={() => setPaymentType('subscription')}>Recurring Subscription</button>
                <button onClick={() => setPaymentType('one-time')}>Buy Credits</button>
            </div>
            <CheckoutForm paymentType={paymentType} customerId={customerId} apiKey={apiKey} />
        </Elements>
    );
};

export default PaymentPage;
// src/App.js
import React from 'react';
import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase.js';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';
import SignIn from './signin.js';
import SignOut from './signout.js';
import Uploader from './uploader.js';
import BulkUploader from './bulkUploader.js';
import ProfileForm from './profileForm.js';
import ConfirmationDialog from './confirmationDialog';

const AuthWrapper = ({useBulkUploader}) => {
  const [user, loading, error] = useAuthState(auth);
  const [shopikUser, setShopikUser] = useState({});
  const [uploaderStyle, setUploaderStyle] = useState(0);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const params = useParams();
  const [userDict, setUserDict] = useState({}); 

  useEffect(() => {
    if (user) {
      handleUserLogin(user, auth.currentUser.providerData[0].providerId);
    }
  }, [user]);

  const handleUserLogin = async (user, signInMethod) => {
    const customerId = params['customer'];
    const dbUserRef = collection(db, "users");
    const userId = user.uid;
    const userRef = doc(dbUserRef, userId);
    const docSnap = await getDoc(userRef);
    
    let userData;
    if (docSnap.exists()) {
      userData = docSnap.data();
      if (userData.customerId !== customerId) {
        setIsDialogVisible(true);
      } else {
        getUploaderStyle(shopikUser)
        setShopikUser(userData);  
      }
      setUserDict(userData);
    } else {
      const newUserData = {
        appVersion: '2.7',
        created: formatCurrentTime(),
        customerId: customerId,
        userId: userId,
        name: user.displayName,
        lastOnline: formatCurrentTime(),
        signInMethod: signInMethod.replace('.com', ''),
      };
      addUserToCustomer(newUserData);
      setUserDict(newUserData);
    }
  };

  const handleConfirm = () => {
    setIsDialogVisible(false);
    addUserToCustomer(userDict);
  };

  const handleCancel = () => {
    auth.signOut();
  };

  const addUserToCustomer = async (shopikUser) => {
    const customerId = params['customer'];
    const dbCustRef = collection(db, "customers");
    const custRef = doc(dbCustRef, customerId);
    const custData = (await getDoc(custRef)).data();
    if (!custData) {
      //TODO error , bad customer ID
      return 
    } else if (custData.uploaderStyle) {
      setUploaderStyle(custData.uploaderStyle )  
    }
    const users = custData.users || [];
    const userExists = users.some(user => user.userId === shopikUser.userId);
    shopikUser.customerId = customerId;
    if (!userExists) {
      users.push(shopikUser);
      await setDoc(custRef, { 'users': users }, { merge: true });
    } 

    const dbUserRef = collection(db, "users");
    const userRef = doc(dbUserRef, shopikUser.userId);
    await setDoc(userRef, shopikUser, { merge: true });
    setShopikUser(shopikUser);  
  };

  const getUploaderStyle = async (shopikUser) => {
    const customerId = params['customer'];
    const dbCustRef = collection(db, "customers");
    const custRef = doc(dbCustRef, customerId);
    const custData = (await getDoc(custRef)).data();
    if (custData.uploaderStyle) {
      setUploaderStyle(custData.uploaderStyle)
    } 
  };

  
  const formatCurrentTime = () => {
    const pad = (num, size) => {
      let s = num.toString();
      while (s.length < size) s = "0" + s;
      return s;
    };

    const date = new Date();
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1, 2);
    const day = pad(date.getUTCDate(), 2);
    const hours = pad(date.getUTCHours(), 2);
    const minutes = pad(date.getUTCMinutes(), 2);
    const seconds = pad(date.getUTCSeconds(), 2);

    return `${year}${month}${day},${hours}:${minutes}:${seconds}+0000`;
  };

  const isComplete = (user) => {
    if (uploaderStyle === 1) {
      return user.name && user.instagram && user.email && user.phoneNumber;
    } else {
      return user.name && user.email && user.phoneNumber;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const updateData = (newData) => {
    setShopikUser((prevData) => ({
      ...prevData,
      ...newData,
    }));
    setIsEditingProfile(false)
  };

  return (
    <>
      { !isDialogVisible && (<>
      {user ? (
        <div>
          {!isComplete(shopikUser) && (
            <div className='center-text'>
            <b>Just a few more steps until you're in!!</b>
            <ProfileForm shopikUser={shopikUser} updateData={updateData} uploaderStyle={uploaderStyle}/>
            <div className="bottom-container">
              <SignOut />
            </div>
            </div>
          )}
          {isComplete(shopikUser) && (
            <>
            {isEditingProfile ? (
               <>
               <ProfileForm shopikUser={shopikUser} updateData={updateData} uploaderStyle={uploaderStyle}/>
               <div className="bottom-container">
                 <SignOut />
               </div>
               </>
            ): ( 
            <>
            { useBulkUploader ? (<BulkUploader shopikUser={shopikUser} uploaderStyle={uploaderStyle}/> ) : (<Uploader shopikUser={shopikUser} uploaderStyle={uploaderStyle}/> )} 
            </>           
            )}
            {!isEditingProfile && (
            <div className="bottom-container">
              <button className="text-button" onClick={() => setIsEditingProfile(true)}>Edit Profile</button>
              <SignOut />
            </div>
            )}            
            </>
          )}
        </div>
      ) : (
        <div className='Uploader'>
        <SignIn />
        </div>
      )}
      </>
      )}
      {isDialogVisible && (
        <ConfirmationDialog
          message="You are about to tie your existing Shopik account to another customer's account. Are you sure you want to do this?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default AuthWrapper;
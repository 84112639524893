// DashboardLayout.js
import React from 'react';
import DashboardTopNav from './dashboardTopNav'; // Your navigation component

export default function DashboardLayout({ children }) {
  return (
    <div className="dashboard-layout">
      <DashboardTopNav />
      <div className="main-content">
        {children}
      </div>
    </div>
  );
}
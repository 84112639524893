import React, { useState } from 'react';

const SignUpForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        organization: '',
        phoneNumber: '',
        email: '',
        password: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // handle form submission
        console.log(formData);
      };

  return (
      <div className="container">
        <div className="info">
          {/* <h2>The best price for cloud GPUs on the market.</h2>
          <p>No yearly commits or unpredictable spot instances.</p>
          <h2>Trusted by more than 100,000 ML engineers and researchers.</h2>
          <p>94% of the top US research universities use Lambda.</p>
          <h2>Pay by the second</h2>
          <p>Only pay for what you use. Starting at $0.50 per hour.</p>
          <h2>Train & fine-tune models on a GPU cloud built for AI workloads</h2>
          <p>PyTorch®, Tensorflow, and Jupyter pre-installed on every GPU instance.</p>
          <h2>Ease of use</h2>
          <p>Launch ML-optimized instances in just a few clicks.</p> */}
        </div>
        <div className="form">
          Create your free account
          <form onSubmit={handleSubmit}>
            <label>
              Account type
              <select name="accountType" onChange={handleChange}>
                <option value="business">Business</option>
                <option value="personal">Personal</option>
              </select>
            </label>
            <label>
              First name
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </label>
            <label>
              Last name
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </label>
            <label>
              Organization (optional)
              <input
                type="text"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
              />
            </label>
            <label>
              Phone number (optional)
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </label>
            <label>
              Email
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </label>
            <label>
              Password
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </label>
            <label>
              <input type="checkbox" name="terms" />
              I agree to Shopik's Privacy Policy, Terms of Service, and Acceptable Use Policy
            </label>
            <button type="submit">Sign up</button>
          </form>
        </div>
      </div>
  );
}
export default SignUpForm;
// src/App.js
import React from 'react';
import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { auth } from '../firebase.js';
import heic2any from "heic2any";
import { submitPhoto, db, appHandoff  } from '../firebase.js'
import ProductEdit from './productEdit.js';

var isStaging = (process.env.REACT_APP_PROJECT_ID == "shopik-stage");

function convertHEICToJPEG(blob) {
  return heic2any({
    blob,
    toType: "image/jpeg",
  })
  .then((conversionResult) => {
    return conversionResult;
  })
  .catch((error) => {
    console.error(error);
    return null; // Handle errors as needed
  });
}

const processPhoto = function(file, callback) {
  const reader = new FileReader();  
  console.log(file);
  reader.onload = function () {
    
    const img = new Image();
    img.onerror = function(event) {
      // Handle the error, possibly calling the callback with an error
      console.error("Error loading image", event);
      convertHEICToJPEG(file).then( (jpgUrl) => {
        processPhoto(jpgUrl, callback);
      });
    };
    img.src = reader.result;
    img.onload = function () {
      // Set the canvas size to reduce the image size
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const maxWidth = 2500; // Adjust the maximum width as needed
      const maxHeight = 2500; // Adjust the maximum height as needed
      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > maxWidth) {
          newWidth = maxWidth;
          newHeight = (img.height * maxWidth) / img.width;
      }

      if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = (img.width * maxHeight) / img.height;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the image on the canvas with the new dimensions
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Convert the canvas content back to a Blob
      canvas.toBlob(function (blob) {
          // Display the resized image in an img element
          const reader2 = new FileReader();

          reader2.onload = function (e) {
              callback(e.target.result, null); // Assuming the first param is the result and the second is an error
          }
          reader2.onerror = function(error) {
              // Handle FileReader error
              console.error("Error reading blob", error);
              callback(null, error);
          };
          reader2.readAsDataURL(blob);
      }, file.type);
    };
  };
  reader.onerror = function(error) {
      // Handle FileReader error
      console.error("Error reading file", error);
      callback(null, error);
  };
  reader.readAsDataURL(file);
};

// Function to format the current date to "YYYYMMDD,HH:MM:SS+0000"
function formatCurrentTime() {
  const pad = (num, size) => {
    let s = num.toString();
    while (s.length < size) s = "0" + s;
    return s;
  };

  const date = new Date();
  const year = date.getUTCFullYear();
  const month = pad(date.getUTCMonth() + 1, 2);
  const day = pad(date.getUTCDate(), 2);
  const hours = pad(date.getUTCHours(), 2);
  const minutes = pad(date.getUTCMinutes(), 2);
  const seconds = pad(date.getUTCSeconds(), 2);

  return `${year}${month}${day},${hours}:${minutes}:${seconds}+0000`;
}

// Function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// Format the current date
let dateString = formatCurrentTime();

// Generate SKU
let sku = (
  dateString.split('+')[0]
    .replace(/,/g, '')
    .replace(/:/g, '')
  + generateUUID().replace(/-/g, '').substring(0, 4)
);

const UploaderStyle = Object.freeze({
  MULTIFILE: 0,
  CLOTHING: 1
});

const Uploader = ({shopikUser, uploaderStyle}) => {
  const params = useParams();
  const uploadsDefault = uploaderStyle === UploaderStyle.CLOTHING ? [
    { 'title': 'Front', 'file': null, 'usingCamera': false, 'text': '' },
    { 'title': 'Back', 'file': null, 'usingCamera': false, 'text': '' },
    { 'title': 'Tag', 'file': null, 'usingCamera': false, 'text': '' },
    { 'title': 'Pic of you in it', 'file': null, 'usingCamera': false, 'text': '' }
  ] : [];
  const [uploads, setUploads] = useState(uploadsDefault);
  const [isAnotherUpload, setIsAnotherUpload] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [productDocument, setProductDocument] = useState(null);
  const [processingIndex, setProcessingIndex] = useState(-1);
  const [sharedText, setSharedText] = useState(''); // Shared text input
  const textInputPlaceholder = uploaderStyle === UploaderStyle.CLOTHING ? 'Type any notes about the product (Size, Brand, Condition)' : 'Type any notes about the product that are helpful for AI to use'

  useEffect(() => {
    if (uploaderStyle === UploaderStyle.CLOTHING) {
      setUploads(uploadsDefault);
    }
  }, [uploaderStyle]);
// Function to encode a file as a base64 string
const encodeFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result.split(',')[1]);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

  const updateState = (didSubmit) => {
    setIsAnotherUpload(true);
    setUploads(uploadsDefault);
    setHasSubmitted(false);
    setProductDocument(null);
    setProcessingIndex(-1);
    setSharedText('');
  };

  const handleFileInputChange = (e, index) => {
    const newFiles = e.target.files;
    if (uploaderStyle === UploaderStyle.MULTIFILE) {
      const newUploads = Array.from(newFiles).map(file => ({
        file: file,
        text: '',
        usingCamera: false,
      }));
      setUploads(newUploads);
    } else {
      const newFile = newFiles[0];
      const newUploads = [...uploads];
      newUploads[index] = { file: newFile, text: '', usingCamera: false };
      setUploads(newUploads);
    }
  };

  const handleTextInputChange = (e) => {
    setSharedText(e.target.value);
  };
  
  const handleDrop = function(e, index) {
    e.preventDefault();
   const newFiles = e.dataTransfer.files;
    if (uploaderStyle === UploaderStyle.MULTIFILE) {
      const newUploads = Array.from(newFiles).map(file => ({
        file: file,
        text: '',
        usingCamera: false,
      }));
      setUploads(newUploads);
    } else {
      const newFile = newFiles[0];
      const newUploads = [...uploads];
      newUploads[index] = { file: newFile, text: '', usingCamera: false };
      setUploads(newUploads);
    }
  }

  const openFileInput = function() {
    const fileInput = document.getElementById('file-input');
    fileInput.click()
    fileInput.addEventListener('change', (e) => {
        handleFileInputChange(e);
    });
  }

  const handleDragEnter = function(e) {
    // e.classList.remove('highlight');
    e.preventDefault();
  }
  
  const handleDragLeave = function(e) {
    // e.classList.add('highlight');
    e.preventDefault();
  }
  
  const handleDragOver = function(e) {
    e.preventDefault();
  }
  
  const openCamera = function() {
    const cameraInput = document.getElementById('camera-input');
    cameraInput.click();
    cameraInput.addEventListener('change', (e) => {
      handleFileInputChange(e);
    });
  }

  const handleCameraToggle = (index) => {
    const newUploads = [...uploads];
    newUploads[index].usingCamera = !newUploads[index].usingCamera;
    setUploads(newUploads);
  };

  const handleAddUpload = () => {
    setUploads([...uploads, { title: 'Additional photo', file: null, text: '', usingCamera: false }]);
  };

  const handleSubmit = async () => {
    if (processingIndex !== -1) return; // Prevent multiple submissions
    setProcessingIndex(0);
    setHasSubmitted(true);

    try {
      const accumulatedResults = [];
      let currentProductDocument = productDocument;

      for (let i = 0; i < uploads.length; i++) {
        const upload = uploads[i];
        const bgRemoval = ((uploaderStyle === 1) && (i > 1)) ? 0 : 1
        console.log(upload)
        const base64Image = await encodeFileAsBase64(upload.file);
        const result = await submitPhoto({
          input: base64Image,
          speechInput: sharedText,
          userId: auth.currentUser.uid,
          customerId: params['customer'],
          productDocument: currentProductDocument,
          currentImageCount: i,
          removeBackgroundLevel: bgRemoval
        });

        accumulatedResults.push(result);

        const resultData = result['data'];
        currentProductDocument = resultData.productDoc;
        const dict = {
          'imageURL': resultData.imageURL,
          'photoIndex': i,
          'expectedImageCount': uploads.length,
          'productDocument': resultData['productDoc'],
          'productImageDocument': resultData['imageDoc'],
          'customerId': params['customer'],
          'userId': shopikUser.userId,
          'sku': sku,
          'appVersion': '2.7'
        };

        if ((uploaderStyle === 1) && (shopikUser.instagram)) {
          dict['additionalShopifyTags'] = '@' + shopikUser.instragram
        }
        appHandoff(dict).then((innerData) => {
          console.log('demo handed off to app ' + innerData);
        });
      }

      setProductDocument(accumulatedResults[0].data.productDoc);
    } catch (error) {
      console.error('Error processing files:', error);
      // Handle error appropriately
    }
  };

  useEffect(() => {
    const processUpload = async (upload) => {
      if (!upload.file) return upload;

      if (upload.usingCamera || upload.file.type.startsWith('image/')) {
        return upload;
      } else {
        const processedFile = await convertHEICToJPEG(upload.file);
        return { ...upload, file: processedFile };
      }
    };

    const processNextUpload = async () => {
      const nextIndex = uploads.findIndex((upload, index) => index > processingIndex && !upload.file);
      if (nextIndex !== -1) {
        const newUploads = [...uploads];
        newUploads[nextIndex] = await processUpload(uploads[nextIndex]);
        setUploads(newUploads);
        setProcessingIndex(nextIndex);
      } else {
        // All uploads processed, handle submission logic here
        setProcessingIndex(-1); // Reset processing index
      }
    };

    if (processingIndex !== -1) {
      processNextUpload();
    }
  }, [processingIndex, uploads]);


  return (
    <div className="Uploader">
      {(uploaderStyle===UploaderStyle.CLOTHING) && !hasSubmitted && (
      <div className='center-text'>
      { !isAnotherUpload && (<><b>You're in babe!<br/>Upload your product pics, and let the AI do its thing!</b>  <div className='example-image'></div></>)}
      { isAnotherUpload && (<b>You did it! <br/>Upload more items, or if you're done, we will text you will your closet is ready to share! :)</b>)}
      </div>
      )}
      {!hasSubmitted && (uploaderStyle===UploaderStyle.CLOTHING) && (
        <>
          {uploads.map((upload, index) => (
        <div key={index}>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileInputChange(e, index)}
            style={{ display: 'none' }}
            id={`file-input-${index}`}
          />
          {!upload.file ? (
            <label className="custom-button" htmlFor={`file-input-${index}`}>
              {upload.title}
            </label>
          ) : (
            <>
              <span>{upload.title}</span>
              <br />
              <label htmlFor={`file-input-${index}`} style={{ cursor: 'pointer' }}>
                <img
                  key={index}
                  id={`uploaded-image-${index}`}
                  src={URL.createObjectURL(upload.file)}
                  alt={`Uploaded Image ${index}`}
                  width={300}
                  style={{ background: 'white', borderRadius: '2px' }}
                />
              </label>
            </>
          )}
        </div>
      ))}
        </>
      )}
      {!hasSubmitted && (uploaderStyle===UploaderStyle.MULTIFILE) && ( 
        <>
        {(uploads.length > 0) && (
          <>
          {uploads.map((upload, index) => (
            <div key={index}>
              <div>
                {upload.file && upload.file instanceof Blob && (
                <img
                  key={index}
                  id={`uploaded-image-${index}`}
                  src={URL.createObjectURL(upload.file)}
                  alt={`Uploaded Image ${index}`}
                  width={300}
                  style={{ background: 'white', borderRadius: '2px' }}
                />
                )}                
              </div>
            </div>
          ))}
          </>
        )}
        {(uploads.length === 0) && (        
        <div id="image-input-area"> 
          <div className="drop-area-desktop" onClick={openFileInput}>
            <div id="drop-area" onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
              <p style={{color: 'black'}}>Drag image file(s) here or<br />click to upload photos</p> 
              <input type="file" id="file-input" accept="image/*" multiple="multiple" onChange={(e) => handleFileInputChange(e, 0)} hidden /> 
            </div>
          </div>
          <div className="drop-area-mobile">
            <div id="upload-photo-button" className="main-button w-button upload-photo" style={{display: 'block'}} onClick={openFileInput}> 
              Upload photos
            </div>
            <br /><br />
            <div id="take-photo-button" className="main-button w-button take-photo" style={{display: 'block'}} onClick={openCamera}>
              Take photos
            </div>
            <input type="file" id="camera-input" capture="environment" accept="image/*" onChange={(e) => handleFileInputChange(e, 0)} multiple="multiple" hidden />
          </div>
          <br /><br />
        </div>
        )}
        </>
      )}
      { !hasSubmitted && (
        <>
      <textarea
      value={sharedText}
      className='custom-multiline-text-input'
      onChange={handleTextInputChange}
      placeholder={textInputPlaceholder}
      />
      {/* <button onClick={handleAddUpload}>Add Upload</button> */}
      <button className="custom-button" onClick={handleSubmit}>Submit</button>
      {(uploaderStyle===UploaderStyle.CLOTHING) && (
        <>
      <div className='center-text'>
        <b>The AI will remove the background and auto-categorize your items!<br/> Easiest way to sell EVER!</b> 
     </div>
      </>
      )}
      </>
      )}
      { (hasSubmitted && !productDocument) && (
       <div className="demo-image image-container">
         <div className='spinner'></div> 
         <>Submitting photos....</>
       </div>
      )}
      { (hasSubmitted && productDocument) && (
        <ProductEdit customerId={params['customer']} productId={productDocument} updateState={updateState} uploaderStyle={uploaderStyle} groupIndex={0}/>
       )}
    </div>
  );

};

export default Uploader;

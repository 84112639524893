import React, { useState, useEffect } from 'react';
import { Container, Typography, Select, MenuItem, Grid, Paper } from '@mui/material';
import { get, post } from './api';
import { useAuth } from './authContext';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const { user, logout } = useAuth();
    const [month, setMonth] = useState("current"); // Default to current month (YYYY-MM)
    const [usageData, setUsageData] = useState(null);
    const navigate = useNavigate();
    const handleLogout = () => {
      logout();
      navigate('/login');
    };
  
    const months = [
        { label: 'Current Month', value: "current" },
        { label: 'Previous Month', value: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 7).replace('-','') },
        { label: 'Two Months Ago', value: new Date(new Date().setMonth(new Date().getMonth() - 2)).toISOString().slice(0, 7).replace('-','') },
        // Add more as needed
    ];

    useEffect(() => {
        // Fetch usage data when the component mounts or the month changes
        const fetchData = async () => {
            //setUsageData({'totalProductImages' : 245, 'totalProductCount': 120 })
            try {
                // Replace with your API endpoint
                const response = await get('usage?month=' + month, '123');
                setUsageData(response);
            } catch (error) {
                console.error('Error fetching usage data:', error);
            }
        };

        fetchData();
    }, [month]);
  //   <div>
  //   <h1>Dashboard</h1>
  //   <p>Welcome, {user?.email}!</p>
  //   <button onClick={handleLogout}>Logout</button>
  // </div>
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Product Usage Dashboard
            </Typography>
            <Select
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                displayEmpty
                fullWidth
                variant="outlined"
            >
                {months.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>

            {usageData ? (
                <Grid container spacing={3} style={{ marginTop: 20 }}>
                    <Grid item xs={12} md={6}>
                        <Paper style={{ padding: 20 }}>
                            <Typography variant="h6">Total Products</Typography>
                            <Typography variant="h4">{usageData.totalProductCount}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper style={{ padding: 20 }}>
                            <Typography variant="h6">Total Product Images</Typography>
                            <Typography variant="h4">{usageData.productImageCount}</Typography>
                        </Paper>
                    </Grid>
                  
                    {/* Add more stats as needed */}
                </Grid>
            ) : (
                <Typography variant="h6" style={{ marginTop: 20 }}>
                    Loading data...
                </Typography>
            )}
        </Container>
    );
};

export default Dashboard;
import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore'; 
import { db } from '../firebase'; 
import { useParams } from 'react-router-dom';

const PartPricingTable = () => {
  const { customer, document } = useParams(); 
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedSections, setExpandedSections] = useState({}); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, `customers/${customer}/pricingEstimates`, document); 
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setData(docSnap.data()); 
        } else {
          setError("No such document!");
        }
      } catch (err) {
        setError("Error fetching document: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [customer, document]);

  const updateFirebase = async (updatedData) => {
    try {
      const docRef = doc(db, `customers/${customer}/pricingEstimates`, document);
      await updateDoc(docRef, updatedData);
      console.log('Document successfully updated in Firestore');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const handleExclude = (partIndex, sectionKey, listingIndex) => {
    const updatedData = { ...data };
    const section = updatedData.partPricingEstimates[partIndex].pricing[sectionKey].listings;

    section.splice(listingIndex, 1);

    setData(updatedData);
    updateFirebase(updatedData);
  };

  const toggleSection = (partIndex, sectionKey) => {
    const sectionIdentifier = `${partIndex}-${sectionKey}`;
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionIdentifier]: !prevState[sectionIdentifier], 
    }));
  };

  const calculateStatistics = (listings) => {
    const prices = listings.map(listing => listing.price);

    if (prices.length === 0) return { max: 0, min: 0, average: 0, median: 0 };

    prices.sort((a, b) => a - b);
    const max = prices[prices.length - 1];
    const min = prices[0];
    const median = prices[Math.floor(prices.length / 2)];
    const average = prices.reduce((sum, price) => sum + price, 0) / prices.length;

    return { max, min, average, median };
  };

  const renderPartListings = (partData, partIndex) => {
    const renderListingsSection = (title, listings, sectionKey) => {
      if (!listings || listings.length === 0) return null;

      const stats = calculateStatistics(listings);

      const sectionIdentifier = `${partIndex}-${sectionKey}`;
      const isExpanded = expandedSections[sectionIdentifier];

      return (
        <div className="pricing-listing-section" key={sectionIdentifier}>
          <h3>{title} Statistics</h3>
          <div className="pricing-statistics">
            <p>Max Price: ${stats.max.toFixed(2)}</p>
            <p>Min Price: ${stats.min.toFixed(2)}</p>
            <p>Average Price: ${stats.average.toFixed(2)}</p>
            <p>Median Price: ${stats.median.toFixed(2)}</p>
          </div>
          <h4 onClick={() => toggleSection(partIndex, sectionKey)} style={{ cursor: 'pointer' }}>
            {title} Listings ({listings.length} items) {isExpanded ? '▲' : '▼'}
          </h4>
          {isExpanded && (
            <div className="pricing-listings-grid">
              {listings.map((listing, index) => (
                <div key={index} className="pricing-listing">
                  <img src={listing.image} alt={listing.title} />
                  <div className="pricing-listing-info">
                    <h4>
                      <a href={listing.link} target="_blank" rel="noopener noreferrer">
                        {listing.title}
                      </a>
                    </h4>
                    <p>Price: ${listing.price}</p>
                    <p>Condition: {listing.condition}</p>
                    <p>Date: {listing.date}</p>
                    <button onClick={() => handleExclude(partIndex, sectionKey, index)}>
                      Exclude
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    };

    return (
      <div key={partIndex} className="pricing-part-section">
        <h2>Part {partIndex + 1}</h2>
        {renderListingsSection('Sold', partData.pricing.sold?.listings, 'sold')}
        {renderListingsSection('Unsold', partData.pricing.unsold?.listings, 'unsold')}
        {renderListingsSection('Active', partData.pricing.active?.listings, 'active')}
      </div>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data || !Array.isArray(data.partPricingEstimates)) {
    return <div>No data available</div>;
  }

  return (
    <div className="pricing-page">
      {data.partPricingEstimates.map((part, index) => renderPartListings(part, index))}
    </div>
  );
};

export default PartPricingTable;
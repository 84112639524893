import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

export default function DashboardTopNav() {
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error("Error logging out: ", error);
      });
  };

  const user = auth.currentUser;

  return (
    <div className="dashboard-container">
      {/* Top section for user info */}
      <div className="top-nav">
        <div className="user-info">
          <span>{user ? user.email : "Guest"}</span>
          <button className="logout-button" onClick={handleLogout}>Sign Out</button>
        </div>
      </div>

      {/* Left-side navigation */}
      <aside className="side-nav">
        <nav className="nav-menu">
          <ul>
            <li><a href="/settings">Settings</a></li>
            <li><a href="/usage">Plan / Usage</a></li>
            <li><a href="/products">Products</a></li>
            <li><a href="mailto:hello@shopik.io">Support</a></li>
          </ul>
        </nav>
      </aside>
    </div>
  );
}
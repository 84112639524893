import heic2any from "heic2any";

// Function to format the current date to "YYYYMMDD,HH:MM:SS+0000"
export function formatCurrentTime() {
  const pad = (num, size) => {
    let s = num.toString();
    while (s.length < size) s = "0" + s;
    return s;
  };

  const date = new Date();
  const year = date.getUTCFullYear();
  const month = pad(date.getUTCMonth() + 1, 2);
  const day = pad(date.getUTCDate(), 2);
  const hours = pad(date.getUTCHours(), 2);
  const minutes = pad(date.getUTCMinutes(), 2);
  const seconds = pad(date.getUTCSeconds(), 2);

  return `${year}${month}${day},${hours}:${minutes}:${seconds}+0000`;
}

// Function to encode a file as a base64 string
export const encodeFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result.split(',')[1]);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

// Function to convert HEIC to JPEG
export const convertHEICToJPEG = (blob) => {
  return heic2any({
    blob,
    toType: "image/jpeg",
  }).then((conversionResult) => {
    return conversionResult;
  }).catch((error) => {
    console.error(error);
    return null; // Handle errors as needed
  });
};

// Function to generate a UUID
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}